<template>
  <div>

    <!-- Hero section -->
    <div id="hero" class="pt-16 kontajner">
      <div class="grid grid-cols-1 lg:grid-cols-2">
        <div class="xl:pr-16 flex items-center">
          <div>
            <h1 class="hero-title text-6xl">Všetko, čo očakávaš od prémiového hostingu, úplne zadarmo.</h1>
            <p class="hero-description">
              Rozbehni svoj nový projekt na platforme, ktorá ťa vždy podrží. HostNow.cz je spoľahlivým webhostingom už pre
              viac ako 500 projektov. Rozbehni svoj nový projekt práve u nás!
            </p>
            <div class="mt-10">
              <a href="#intro" class="btn btn-blue">Chcem vedieť viac!</a>
            </div>
          </div>
        </div>
        <div class="hidden lg:block">
          <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" id="blobSvg">
            <defs>
              <clipPath id="shape">
                <path id="blob" d="M445,290Q403,330,381.5,369.5Q360,409,316.5,425Q273,441,225.5,441Q178,441,122.5,426.5Q67,412,69.5,353Q72,294,50.5,244.5Q29,195,67.5,158.5Q106,122,142,91Q178,60,228.5,36.5Q279,13,312,62.5Q345,112,376,140Q407,168,447,209Q487,250,445,290Z" fill="#d1d8e0"></path>
              </clipPath>
            </defs>
            <image x="0" y="0" width="100%" height="100%" clip-path="url(#shape)" xlink:href="https://images.unsplash.com/photo-1560732488-6b0df240254a?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80" preserveAspectRatio="none"></image>
          </svg>
        </div>
      </div>
    </div>

    <!-- Introduction with statistics -->
    <div id="intro">
      <h1 class="text-4xl">Sme nová značka na trhu</h1>
      <p class="intro-description">
        Sme odhodlaní prekopať systém, pretože tiež máme plné zuby drahých webhostingov,
        ktorí zamykajú funkcie za "paywalls", aj keď ich prakticky nič nestoja.
      </p>
      <div class="md:flex justify-center mt-12">
        <div class="statistic">
          <h2>500+</h2>
          <p>projektov hostuje u nás</p>
        </div>
        <div class="statistic">
          <h2>400+</h2>
          <p>použivateľov nám dôveruje</p>
        </div>
        <div class="statistic">
          <h2>3</h2>
          <p>roky zbierame skúsenosti</p>
        </div>
      </div>
    </div>

    <!-- Our goal -->
    <div id="goal">
      <div class="lg:grid lg:grid-cols-2 kontajner text-left">
        <div class="flex items-center">
          <div>
            <h1 class="text-4xl w-4/5">Máš nápad? Pomôžeme ti ho zrealizovať!</h1>
            <p class="intro-description">
              Nechceme, aby cena webhostingu bola prekážkou pri tvorbe tvojho nového projektu. Preto ti ponúkneme úplne
              zadarmo všetko čo bude v našich možnostiach, aby tvoj projekt mohol rásť.
            </p>
          </div>
        </div>
        <div class="text-center pt-4 lg:pt-0">
          <div class="feature">
            <font-awesome-icon icon="save" class="text-4xl mb-4"/>
            <p>1GB úložiska</p>
          </div>
          <div class="feature">
            <font-awesome-icon icon="newspaper" class="text-4xl mb-4"/>
            <p>Error a Access logy</p>
          </div>
          <div class="feature">
            <font-awesome-icon icon="globe" class="text-4xl mb-4"/>
            <p>Dočasnú doménu</p>
          </div>
          <div class="feature">
            <font-awesome-icon icon="ethernet" class="text-4xl mb-4"/>
            <p>∞ subdomén a aliasov</p>
          </div>
          <div class="feature">
            <font-awesome-icon icon="lock" class="text-4xl mb-4"/>
            <p>SSL certifikát</p>
          </div>
          <div class="feature">
            <font-awesome-icon icon="database" class="text-4xl mb-4"/>
            <p>∞ MySQL databáz</p>
          </div>
          <div class="feature">
            <font-awesome-icon icon="clock" class="text-4xl mb-4"/>
            <p>Pravidelné zálohy</p>
          </div>
          <div class="feature">
            <font-awesome-icon icon="key" class="text-4xl mb-4"/>
            <p>FTP a SFTP prístup</p>
          </div>
          <div class="feature">
            <font-awesome-icon icon="plus" class="text-4xl mb-4"/>
            <p>a o mnoho viac</p>
          </div>
        </div>
      </div>
    </div>

    <!-- What do we expect -->
    <div id="expectations">
      <h1 class="text-4xl">Aké projekty hľadáme</h1>
      <p class="intro-description">
        Pár príkladov niekoľko projektov, ktoré sme veľmi ochotní podporiť pri ich rozvoji súčasťou tejto kampane
      </p>
      <div class="flex justify-center mt-12">
        <div class="text-left">
          <p class="text-gray-300 mb-3">
            <true/>Webstránka pre tvoju komunitu, klub, triedu, tím...
          </p>
          <p class="text-gray-300 mb-3">
            <true/>Neziskový projekt, ktorý pomôže okoliu
          </p>
          <p class="text-gray-300 mb-3">
            <true/>Edukačné projekty
          </p>
          <p class="text-gray-300 mb-3">
            <true/>Fórum napríklad pre priaznivcov technólogii...
          </p>
          <p class="text-gray-300 mb-3">
            <true/>Verejnoprospešné projekty
          </p>
          <p class="text-gray-300 mb-3">
            ... a mnohé ďalšie!
          </p>
        </div>
      </div>
    </div>


    <!-- Our goal -->
    <div id="contact">
      <div class="lg:grid lg:grid-cols-2 kontajner text-left">
        <div class="flex items-center md:mb-8">
          <div>
            <h1 class="text-4xl w-4/5">Tak čo? Ideš do toho?</h1>
            <p class="intro-description">
              Pokiaľ si sa našiel v jednej kategórii vyššie, alebo sa cítiš sebaisto, že tvoj projekt má zmysel, určite
              nám o tom daj vedieť! Za pokus nič nedáš..
            </p>
          </div>
        </div>
        <div class="flex justify-center">
          <div class="contact-box">
            <form @submit.prevent="send_form" @keydown="form.onKeydown($event)">
              <div class="form-input-group">
                <label>Tvoje meno <required/></label>
                <input :disabled="formsent" type="text" name="name" v-model="form.name" maxlength="100" required/>
                <span v-if="form.errors.has('name')" class="text-red-500">Toto pole je povinné</span>
              </div>
              <div class="form-input-group">
                <label>Email, na ktorý ti máme odpovedať <required/></label>
                <input :disabled="formsent" required type="email" name="email" v-model="form.email" maxlength="255"/>
                <span v-if="form.errors.has('email')" class="text-red-500">Tvoj email je buď príliš dlhý alebo nevyzerá ako email :o</span>
              </div>
              <div class="form-input-group">
                <label>Názov tvojho projektu <required/></label>
                <input :disabled="formsent" required type="text" name="project" v-model="form.project" maxlength="100"/>
                <span v-if="form.errors.has('project')" class="text-red-500">Toto pole je povinné</span>
              </div>
              <div class="form-input-group">
                <label>Doména, na ktorej budeš svoj projekt prevádzkovať <required/></label>
                <input :disabled="formsent" required type="text" name="domain" v-model="form.domain" maxlength="255" pattern="([a-z0-9\-]+\.)*[a-z0-9\-]{2,}\.[a-z]{2,}"/>
                <span v-if="form.errors.has('domain')" class="text-red-500">Toto podľa nás nevyzerá ako doména :/</span>
              </div>
              <div class="form-input-group">
                <label>Krátky popis tvojho projektu <required/></label>
                <textarea :disabled="formsent" name="description" v-model="form.description" minlength="100" maxlength="1000" required/>
                <span v-if="form.errors.has('description')" class="text-red-500">Skús sa zmestiť do rozmedzia 100 až 1000 znakov :)</span>
              </div>
              <div class="form-input-group">
                <label>Úložisko, ktoré budeš potrebovať</label>
                <input type="text" name="storage" v-model="form.storage" maxlength="50" :disabled="formsent"/>
                <span v-if="form.errors.has('storage')" class="text-red-500">Toto by nemalo zabrať viac ako 50 znakov :D</span>
              </div>
              <div class="text-center">
                <button class="btn btn-blue" :disabled="form.busy" v-if="!formsent" type="submit">Odoslať</button>
                <span v-else class="text-green-500">Formulár bol odoslaný! Ak od nás nedostaneš odpoveď hneď, očakávaj
                ju najneskôr do 3 pracovných dní :)</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- What do we expect -->
    <div id="faq">
      <h1 class="text-4xl">Často kladené otázky</h1>
      <p class="intro-description">
        Ak si stále nerozhodný, skús si prezrieť tieto často kladené otázky, ktoré sme spracovali, alebo nám rovno napíš
        na <a href="mailto:info@hostnow.cz" class="text-blue-500 hover:underline">info@hostnow.cz</a>
      </p>
      <div class="flex justify-center mt-12">
        <div class="question-wrapper">
          <question
              question="Je táto kampaň naozaj zadarmo?"
              answer="Áno, celá kampaň nezahŕňa jedinú ani skrytú platbu."
          />
          <question
              question="Čo vám to prináša? Ako si to môžete dovoliť?"
              answer="Pre nás ako spoločnosť má táto kampaň zmysel, keďže veríme, že nám urobí reklamu. To je náš jediný
               zámer a naša jediná výhoda, ktorú nám táto kampaň prináša."/>
          <question
              question="Dokedy kampaň trvá?"
              answer="Výhody, ktoré plynú z tejto kampane Vám môžeme zaručiť minimálne do 31.12.2022, avšak ak by mala
                      táto kampaň skončiť, určite ponúkneme všetkým zúčastneným projektom alternatívu, kam migrovať.
                      Zároveň migráciu v rámci HostNow.cz zabezpečíme plynule pre Vás bez akýchkoľvek starostí.
                      (Samozrejme s vašim súhlasom) Stojíme si za všetkým, čo píšeme na tejto stránke a preto nebudeme
                      vykonávať žiadne nezmyselné kroky, ktoré by Vášmu projektu spôsobili problémy."/>
          <question
              question="Čo ak je môj projekt iba 'na papieri'?"
              answer="Žiadny problém! Pokiaľ vo svoj projekt veríš a si ochotný mu obetovať svoj čas, sme pripravení ťa
                      podporiť! Daj nám o tom ale vedieť vo formulári, že projekt ešte neexistuje :)"/>
        </div>
      </div>
    </div>

  </div>



</template>

<script>
import Question from "@/components/question";
import Form from 'vform'

export default {
  name: "partnership-campaign",
  components: {Question},
  layout: 'modern',

  metaInfo() {
    return {title: "Rozbehni svoj projekt u nás!"}
  },

  data: () => {
    return ({
      form: new Form ({
        name: '',
        email: '',
        project: '',
        domain: '',
        description: '',
        storage: ''
      }),
      formsent: false
    })
  },

  methods: {
    async send_form() {
      // eslint-disable-next-line no-unused-vars
      const response = await this.form.post('https://admin.hostnow.cz/api/campaign')
      this.formsent = true;
    }
  }
}
</script>

<style scoped>

.question-wrapper {
  width: 50%;
  max-width: 700px;
  min-width: 300px;
}

.form-input-group {
  @apply mb-4;
}

.contact-box {
  display: inline-block;
  @apply p-6 text-left rounded-md shadow-xl;
  background-color: #0c2344;
  width: 75%;
}

.contact-box label {
  font-family: 'Karla', sans-serif;
  @apply text-white mb-2 block;
}

.contact-box input, .contact-box textarea {
  font-family: 'Space Mono', monospace;
  @apply px-4 py-2 rounded-md text-gray-400 mb-1;
  width: 100%;
  background-color: #081a34;
}

.feature {
  display: inline-block;
  @apply text-white rounded-md text-center m-2
  py-6 px-12 shadow-xl;
  background-color: #0c2344;
}
.kontajner {
  @apply px-3 sm:px-16 md:px-20 xl:px-48;
}

#goal {
  background: #000D20;
  @apply py-24 text-center;
}

.statistic {
  @apply text-white rounded-md text-center m-2
          py-6 px-12 shadow-xl;
  background-color: #0c2344;
}
.statistic h2 {
  @apply text-4xl font-extrabold mb-4;
  font-family: 'Space Mono', monospace;
}

#contact {
  background: #000D20;
  @apply py-24 text-center;
}

#faq {
  background: #000D20;
  @apply py-24 text-center;
}


#expectations {
  background: #000D20;
  @apply py-24 text-center;
}

#intro {
  background: #000D20;
  @apply py-24 text-center;
}
/*
.intro-box {
  @apply my-16 xl:mx-48 lg:mx-32 md:mx-16 sm:mx-8 mx-4 rounded-xl shadow-2xl;
  height: 100px;
  background-color: #142752;
}
*/

#hero {
  height: 100vh;
  width: 100%;
  background: #000D20;
  min-height: 850px;
  @apply flex align-middle justify-center items-center;
}

.intro-description {
  font-family: 'Karla', sans-serif;
  display: inline-block;
  max-width: 500px;
  @apply text-gray-500 text-lg;
}

h1 {
  @apply font-extrabold text-white drop-shadow-2xl mb-6;
}

.hero-title {
  font-family: 'Merriweather', serif;
}

.hero-description {
  font-family: 'Karla', sans-serif;
  @apply text-gray-500 text-lg;
}

</style>